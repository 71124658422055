import React, { ReactElement, useContext, useState } from "react";
import { NavigationBar, Icon, useRequestInit, MenuSeperator, SupportMenu, NotificatiesMenu, AdviseurMenu, SupportTicketModalAjax, SupportRemoteAssistanceModal, AuthContext, InvalidAdviesboxResultErrorPage, ErrorPage, PageLoading, useFeature, Notificaties2Menu } from "adviesbox-shared";
import { AppDataContext } from "../../../navigation/appdata-context";
import { Dropdown } from "react-bootstrap";
import { DossierRouteParams } from "../../types";
import classes from "./TopNavbar.module.scss";
import "./TopNavigation.scss";
import useAbortableFetch from "use-abortable-fetch";
import { VestigingenOutput, OrganisatiesOutput } from "../../../.generated/licenties/licentiestypes";
import UserDetailsContext from "../../../user-details/user-details-context";
import { useParams } from "react-router-dom";

const TopNavigation = (): ReactElement => {
  const { userDetails } = useContext(UserDetailsContext);
  const { params, settings, requestInit } = useRequestInit<DossierRouteParams>();
  const { vestiging: vestigingId } = useParams<{ vestiging: string }>();
  const { activePage } = useContext(AppDataContext);
  const { user } = useContext(AuthContext);
  const [showMeldingmaken, setShowMeldingMaken] = useState(false);
  const [showRemoteAssistance, setShowRemoteAssistance] = useState(false);
  const [showInvalidAdviesboxResultErrorPage, setShowInvalidAdviesboxResultErrorPage] = useState(false);
  const featureNotificaties2 = useFeature("FeatureNotificaties2");

  const vestiging = useAbortableFetch<VestigingenOutput>(`${settings.licentiesOrigin}/Vestigingen/${vestigingId}`, requestInit);  
  const organisatieId = userDetails.organisatieId ? userDetails.organisatieId : null;
  const organisatieUrl = organisatieId ? `${settings.licentiesOrigin}/Organisaties/${organisatieId}` : null;
  const organisatie = useAbortableFetch<OrganisatiesOutput>(organisatieUrl, requestInit);

  if (!user) {
    return <ErrorPage error={new Error("user not logged in")} />;
  }

  if (vestiging.error) {
    return <ErrorPage error={vestiging.error} />;
  }

  if (organisatie.error) {
    return <ErrorPage error={organisatie.error} />;
  }

  if (vestiging.loading || !vestiging.data || organisatie.loading || !organisatie.data) {
    return <PageLoading />;
  }

  const vestigingNaam =
    vestiging.data && typeof vestiging.data !== "string" && vestiging.data.isValid
      ? vestiging.data.vestigingen?.[params.vestiging].naam
      :  /*istanbul ignore next */ "";
  const organisatieNaam =
    organisatie.data && typeof organisatie.data !== "string" && organisatieId
      ? organisatie.data.organisaties?.[organisatieId].naam
      :  /*istanbul ignore next */ "";

  /*istanbul ignore next */
  if (showInvalidAdviesboxResultErrorPage) {
    return <InvalidAdviesboxResultErrorPage />;
  }
  
  return (
    <NavigationBar title={activePage}>
      <div className="top-navigation__dossier">
        <Dropdown className="top-navigation__item">
          <Dropdown.Toggle variant="link" id="dropdown-basic-dossier" size="sm">
            <div>
              Dossier
              <span className="ml-2">
                <Icon name="chevron" alt="drop-down" iconSize="xs" />
              </span>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              href={`${window.location.origin}/vestiging/${params.vestiging}/zoeken`}
              id="dossier-sluiten"
              data-testid="dossier-sluiten"
            >
              Sluiten
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <MenuSeperator />
      <SupportMenu
        showMeldingMaken={/*istanbul ignore next */ () => setShowMeldingMaken(true)}
        showRemoteAssistance={/*istanbul ignore next */ () => setShowRemoteAssistance(true)}
        classes={{
          iconblack: classes.iconblack,
          iconpadding: classes.iconpadding
        }} />
       {!featureNotificaties2 && <NotificatiesMenu />}
       {featureNotificaties2 && <Notificaties2Menu />}
      <AdviseurMenu vestigingNaam={vestigingNaam} organisatieNaam={organisatieNaam} />
      {showMeldingmaken && user && (
        <SupportTicketModalAjax
          adviesdossierId={params.dossierId ?? null}
          user={user}
          closeModal={/*istanbul ignore next */ () => setShowMeldingMaken(false)}
          showInvalidResultErrorPage={/*istanbul ignore next */ () => setShowInvalidAdviesboxResultErrorPage(true)}
          vestigingId={params.vestiging}
        />
      )}
      {showRemoteAssistance && (
        <SupportRemoteAssistanceModal closeModal={/*istanbul ignore next */ () => setShowRemoteAssistance(false)} />
      )}
    </NavigationBar>
  );
};

TopNavigation.displayName = "TopNavigation";

export default TopNavigation;
