import { AuthContext, FeatureProvider } from "adviesbox-shared";
import React, { ReactElement, useContext } from "react";
import AuthenticatedHdnDossierApp from "./AuthenticatedHdnDossierApp";
import UnauthenticatedHdnDossierApp from "./UnauthenticatedHdnDossierApp";

const HdnDossierApp = (): ReactElement => {
  const { user } = useContext(AuthContext);

  if (user) {
    return (
      <FeatureProvider>
        <AuthenticatedHdnDossierApp />
      </FeatureProvider>
    );
  }

  return <UnauthenticatedHdnDossierApp />;
};

HdnDossierApp.displayName = "HdnDossierApp";

export default HdnDossierApp;
