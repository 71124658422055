/* istanbul ignore file */
import React, { ReactElement, useContext, useEffect, useState, useMemo } from "react";
import { Route, RouteComponentProps, useRouteMatch } from "react-router-dom";
import {
  ErrorPage,
  getPathname,
  useRequestInit,
  NotificatiesProvider,
  getCookie,
  getOTAP,
  withErrorBoundary,
  SigninSilentOidc,
  useFeature,
  Notificaties2Provider
} from "adviesbox-shared";
import ParamRouteProvider from "../shared/paramrouting/paramrouting-provider";
import { DossierRouteParams } from "../shared/types";
import AuthenticatedHdnDossierAppWithNav from "./AuthenticatedHdnDossierAppWithNav";
import UserDetailsContext from "../user-details/user-details-context";
import { MedewerkerOrganisatieOutput, Medewerker } from "../.generated/licenties/licentiestypes";
import useAbortableFetch from "use-abortable-fetch";
import ZoekschermAjax from "../zoekscherm/zoekscherm-ajax";

const AuthenticatedHdnDossierApp = (): ReactElement => {
  const featureNotificaties2 = useFeature("FeatureNotificaties2");
  const { user, settings, requestInit } = useRequestInit();
  const { params } = useRouteMatch<DossierRouteParams>();

  const [medewerkerUrl, setMedewerkerUrl] = useState<string | null>(null);
  const [medewerker, setMedewerker] = useState<Medewerker | null>(null);
  const [medewerkerId, setMedewerkerId] = useState<string | null>(null);
  const [organisatieId, setOrganisatieId] = useState<string | null>(null);
  const [vestigingId, setVestigingId] = useState<string>("");
  const vestigingIdCookie = getCookie(`${getOTAP(window.location.hostname)}_vestigingid`);

  const { setUserDetails } = useContext(UserDetailsContext);

  useMemo(() => {
    setMedewerkerUrl(user?.profile ? `${settings.licentiesOrigin}/Medewerkers/current` : null);
  }, [setMedewerkerUrl, user, settings]);

  const medewerkerRequest = useAbortableFetch<MedewerkerOrganisatieOutput>(medewerkerUrl, requestInit);

  useEffect(() => {
    if (
      !medewerkerRequest ||
      medewerkerRequest.loading ||
      medewerkerRequest.error ||
      !medewerkerRequest.data ||
      typeof medewerkerRequest.data === "string" ||
      !medewerkerRequest.data.isValid
    ) {
      setMedewerker(null);
      setMedewerkerId(null);
      setOrganisatieId(null);
      return;
    }

    setMedewerker(medewerkerRequest.data.medewerker);
    setMedewerkerId(medewerkerRequest.data.medewerkerId);
    setOrganisatieId(medewerkerRequest.data.organisatieId);
  }, [medewerkerRequest, setMedewerker, setMedewerkerId]);

  useEffect(() => {
    if (typeof vestigingIdCookie === "string") {
      setVestigingId(vestigingIdCookie);
    }
  }, [vestigingIdCookie, setVestigingId]);

  useEffect(() => {
    if (!medewerkerId || !organisatieId || !medewerker) {
      return;
    }

    const newUserDetails = {
      ...medewerker,
      medewerkerId: medewerkerId,
      organisatieId: organisatieId
    };

    setUserDetails(newUserDetails);
  }, [setUserDetails, medewerker, medewerkerId, organisatieId]);

  if (user && medewerkerRequest.error == null && (medewerkerRequest.loading || !medewerkerId || !vestigingId))
    return <></>;

  if (!user || !user.profile) {
    return <ErrorPage error={Error("Geen gebruiker of geen rechten toegekend voor deze gebruiker")} data={null} />;
  }

  if (medewerkerRequest.error) {
    return <ErrorPage error={medewerkerRequest.error} data={medewerkerRequest.data} />;
  }

  if (!medewerkerId || !vestigingId) {
    return <ErrorPage error={Error("Communicatie fout, neem contact op met klantenservice")} data={null} />;
  }

  const notificatiueChildren = (
    <ParamRouteProvider route={params}>
      <Route
        exact
        path={"/vestiging/:vestiging"}
        render={
          /* istanbul ignore next */ ({ history }: RouteComponentProps<DossierRouteParams>) => {
            const base = getPathname().charAt(getPathname().length - 1);
            if (base === "/") {
              history.push(`${getPathname()}zoeken`);
              return <></>;
            }
            history.push(`${getPathname()}/zoeken`);
            return <></>;
          }
        }
      />
      <Route path={"/vestiging/:vestiging/zoeken"} exact component={ZoekschermAjax} />
      <Route path="/vestiging/:vestiging/dossier/:dossierId" component={AuthenticatedHdnDossierAppWithNav} />
      <Route path="/silent-redirect" component={withErrorBoundary(SigninSilentOidc)} />
    </ParamRouteProvider>
  );

  return (
    <div data-testid="authenticated-app">
      {featureNotificaties2 && (
        <Notificaties2Provider sourceApp="HDN" vestigingId={vestigingId}>
          {notificatiueChildren}
        </Notificaties2Provider>
      )}
      {!featureNotificaties2 && (
        <NotificatiesProvider
          reloadTimeInSeconds={60}
          medewerkerId={medewerkerId}
          sourceApp="HDN"
          vestigingId={vestigingId}
        >
          {notificatiueChildren}
        </NotificatiesProvider>
      )}
    </div>
  );
};

AuthenticatedHdnDossierApp.displayName = "AuthenticatedHdnDossierApp";

export default AuthenticatedHdnDossierApp;
