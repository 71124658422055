import React, { ReactElement } from "react";
import { connect, useFormikContext, FormikContextType } from "formik";
import {
  DataGrid,
  AdviesBoxColumn,
  createSpanWithId,
  getFormattedDateTime,
  Icon,
  useRequestInit,
  SettingsType,
  SubscribeNotifyButton
} from "adviesbox-shared";
import { DossierSchemaType } from "../infra/dossier-schema";
import { getStatusFromSx } from "../../zoekscherm/infra/map-sx-status";
import memoizeOne from "memoize-one";
import { CellInfo } from "react-table-6";
import { Button } from "react-bootstrap";
import { LogboekXmlModal } from "./logboek-xml-modal";
import { User } from "oidc-client";

// TODO: Toevoegen als scherm ontwikkeld wordt
export const InfoButtonLogboek = memoizeOne(
  (
    setSelectedRow?: React.Dispatch<React.SetStateAction<number>>,
    setShowPopup?: React.Dispatch<React.SetStateAction<boolean>>
  ): ((cellInfo: CellInfo) => ReactElement) => {
    const CellInfoButton = (c: CellInfo): ReactElement => (
      <div className="d-flex align-items-center justify-content-center w-100 h-100">
        <Button
          data-testid={`btn-info-${c.index}`}
          id={`btn-info-${c.index}`}
          variant="outline-secondary"
          aria-label="bewerk knop"
          onClick={(): void => {
            setSelectedRow && setSelectedRow(c.index);
            setShowPopup && setShowPopup(true);
          }}
        >
          <Icon name="documentpreview" alt="Info" />
        </Button>
      </div>
    );

    CellInfoButton.displayName = "CellInfoButton";

    return CellInfoButton;
  }
);

export const RetryButtonLogboek = memoizeOne(
  (
    context: FormikContextType<any>,
    settings: SettingsType,
    user: User | null,
    vestiging: string
  ): ((cellInfo: CellInfo) => ReactElement) => {
    const CellRetryButton = (c: CellInfo): ReactElement => {
      const url = `${settings.foundationOrigin}/HdnOnline/bus/RetryBericht/${c.original.berichtId}`;
      const requestInitRetry = {
        headers: {
          authorization: `${user?.token_type} ${user?.access_token}`,
          "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
          "Content-Type": "application/json;charset=UTF-8",
          vestigingId: vestiging
        },
        method: "POST"
      };
      return (
        <div className="d-flex align-items-center justify-content-center w-100 h-100">
          {/* <Button
            data-testid={`btn-retry-${c.index}`}
            id={`btn-retry-${c.index}`}
            variant="outline-secondary"
            aria-label="retry knop"
            disabled={retryEnabled.includes(c.index)}
            hidden={c.original.status !== "Tijdelijke fout"}
            onClick={(): void => {
              retryPost(settings, user, c.original.berichtId, vestiging);
              setRetryEnabled([...retryEnabled, c.index]);
            }}
          >
            <Icon
              name="refresh"
              alt={
                retryEnabled.includes(c.index)
                  ? "Is reeds ge-retried (ververs eventueel scherm voor nieuwe status)"
                  : "Retry"
              }
            />
          </Button> */}

          <SubscribeNotifyButton
            context={context}
            buttonText=""
            requestInit={requestInitRetry}
            url={url}
            alwaysShow={true}
            icon="refresh"
            hidden={c.original.status !== "Tijdelijke fout"}
            variant="outline-secondary"
            aria-label="retry knop"
            isSubmitButton={false}
          ></SubscribeNotifyButton>
        </div>
      );
    };

    CellRetryButton.displayName = "CellRetryButton";

    return CellRetryButton;
  }
);

export const mapBerichtType = (berichtType: string): string => {
  switch (berichtType) {
    case "AX":
      return "Aanvraag hypotheek";
    case "LX":
      return "Aanvraag leven";
    case "DA":
      return "Documenten aanvraag";
    case "OX":
      return "Offerte";
    case "SX":
      return "Status";
    case "VX":
      return "Validatie";
    case "XX":
      return "Infrastructuur";
    case "DX":
      return "Document";
    default:
      return "Onbekend";
  }
};

export const mapStatusMelding = (berichtType: string, status: string, melding: string): string => {
  if (berichtType === "SX") return getStatusFromSx(melding);
  if (melding == null) return status;
  return `${status}: ${melding}`;
};

const Logboek = (): ReactElement => {
  const [selected, setSelectedRow] = React.useState(0);
  const [isModalOpen, setModalOpen] = React.useState(false);
  const props = useFormikContext<DossierSchemaType>();
  const { setFieldValue, values } = props;
  const { settings, user, params } = useRequestInit<{
    vestiging: string;
    adviesdossier: string;
  }>();

  const columns: AdviesBoxColumn[] = [
    {
      Header: "Datum",
      accessor: "datum",
      Cell: (c): ReactElement => {
        const datum = getFormattedDateTime(new Date(c.original.datum));
        return createSpanWithId(c.index, 0, datum, datum);
      },
      width: 175
    },
    {
      Header: "Bericht",
      accessor: "bericht",
      Cell: (c): ReactElement => {
        const bericht = mapBerichtType(c.original.soortBericht);
        return createSpanWithId(c.index, 2, bericht, bericht);
      }
    },
    {
      Header: "Status",
      accessor: "Status",
      Cell: (c): ReactElement => {
        const status = mapStatusMelding(c.original.soortBericht, c.original.status, c.original.statusMelding);
        return createSpanWithId(c.index, 1, status, status);
      },
      width: 175
    },
    {
      Cell: InfoButtonLogboek(setSelectedRow, setModalOpen),
      maxWidth: 30
    }
  ];
  if (values?.logboekregels?.some(x => x.status === "Tijdelijke fout")) {
    columns.push({
      Cell: RetryButtonLogboek(props, settings, user, params.vestiging),
      maxWidth: 30
    });
  }
  /* istanbul ignore next */
  const onDataGridChange = (data?: DossierSchemaType[]): void => {
    if (!data || !data.length) return;
    setFieldValue("logboekregels", data);
  };

  return (
    <div className="m-2 pb-2">
      <DataGrid
        name={"logboekregels"}
        columns={columns}
        resizable={false}
        sortable={true}
        sortedCallback={onDataGridChange}
        filterable={false}
      />

      {isModalOpen && (
        <LogboekXmlModal
          berichtId={values?.logboekregels[selected]?.berichtId ?? ""}
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
        ></LogboekXmlModal>
      )}
    </div>
  );
};

export default connect(Logboek);
